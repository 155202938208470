// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || ''
const CLIENT_NAME = process.env.CLIENT_NAME || ''
const ENVIRONMENT = process.env.ENVIRONMENT || 'production'
// const BrokerAPI = process.env.API_ENDPONT
const environment = CLIENT_NAME + '-' + ENVIRONMENT

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,
  environment,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  initialScope: {
    tags: { type: 'client' },
  },
  integrations: [
    new Sentry.Integrations.BrowserTracing({
      // Enable when backend allow sentry-trace https://docs.sentry.io/platforms/javascript/guides/nextjs/performance/instrumentation/automatic-instrumentation/#tracingorigins
      // tracingOrigins: [BrokerAPI],
    }),
  ],
})
